<template>
  <div style=" position: absolute; width: 100%; height: 100%;">
    <br><br>
    <div class="text-center">
      <h1>Mensajes de alerta</h1>

      <br><br><br><br><br><br><br><br><br><br><br><br><br><br>

      <button class="btn btn-success me-2" @click="success()">Success</button>
      <button class="btn btn-danger me-2" @click="error()">Error</button>
      <button class="btn btn-warning me-2" @click="warning()">Warning</button>
      <button class="btn btn-info me-2" @click="info()">Info</button>
      <button class="btn btn-secondary" @click="question()">Question</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Prueba',
  methods: {
    success: function(mensaje) {
      iu.msg.success("Hola mundo como estas")
    },
    error: function(mensaje) {
      iu.msg.error("Hola mundo como estas")
    },
    warning: function(mensaje) {
      iu.msg.warning("Hola mundo como estas")
    },
    info: function(mensaje) {
      iu.msg.info("Hola mundo como estas")
    },
    question: function(mensaje) {
      iu.msg.question("Hola mundo como estas")
    }
  }
}
</script>

<style scoped>
</style>